// Generated by Framer (46f1e3d)

import { addFonts, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ucS7klFzj"];

const serializationHash = "framer-C8iqn"

const variantClassNames = {ucS7klFzj: "framer-v-xmpg92"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translateY(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ucS7klFzj", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-xmpg92", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ucS7klFzj"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.h2 style={{"--font-selector": "R0Y7UGx1cyBKYWthcnRhIFNhbnMtNTAw", "--framer-font-family": "\"Plus Jakarta Sans\", \"Plus Jakarta Sans Placeholder\", sans-serif", "--framer-font-size": "24px", "--framer-font-weight": "500", "--framer-letter-spacing": "-0.5px", "--framer-line-height": "1.5em", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-1of0zx5, rgb(119, 119, 119))"}}>Designing experiences that resonate with users and deliver business impact since 2014</motion.h2></React.Fragment>} className={"framer-oq89fb"} fonts={["GF;Plus Jakarta Sans-500"]} layoutDependency={layoutDependency} layoutId={"E4uYJoySX"} style={{"--extracted-1of0zx5": "rgb(119, 119, 119)", "--framer-paragraph-spacing": "0px"}} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-C8iqn.framer-1xedehn, .framer-C8iqn .framer-1xedehn { display: block; }", ".framer-C8iqn.framer-xmpg92 { height: 72px; overflow: hidden; position: relative; width: 568px; }", ".framer-C8iqn .framer-oq89fb { flex: none; height: auto; left: 0px; max-width: 100%; overflow: visible; position: absolute; right: 0px; top: 50%; white-space: pre-wrap; word-break: break-word; word-wrap: break-word; z-index: 1; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 72
 * @framerIntrinsicWidth 568
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerwOxWojgKn: React.ComponentType<Props> = withCSS(Component, css, "framer-C8iqn") as typeof Component;
export default FramerwOxWojgKn;

FramerwOxWojgKn.displayName = "button";

FramerwOxWojgKn.defaultProps = {height: 72, width: 568};

addFonts(FramerwOxWojgKn, [{explicitInter: true, fonts: [{family: "Plus Jakarta Sans", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_m07NTxXUEKi4Rw.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})